export default function ResourceLinksData(resourceLinkKey) {
    let result = [];
    switch(resourceLinkKey){
        case 'AdultDayCareAndHealthCenters':
            result = [
                {
                    name: 'Arbor Adult Day Care, Inc.',
                    addressLine1: '1701 Morgan Ave,',
                    addressLine2: 'Corpus Christi, TX 78404',
                    phoneNumber: '361-879-0000'
                },{
                    name: 'Corpus Christi Adult Day Care, Ltd.',
                    addressLine1: '2020 Gollihar Rd,',
                    addressLine2: 'Corpus Christi, TX 78416',
                    phoneNumber: '361-855-5050'
                },{
                    name: 'La Mansion Adult Day Care',
                    addressLine1: '210 N Upshaw Blvd,',
                    addressLine2: 'Robstown, TX 78380',
                    phoneNumber: '361-387-7474'
                },{
                    name: 'National Adult Day Care Inc.',
                    addressLine1: '619 S. Staples St,',
                    addressLine2: 'Corpus Christi, TX 78401',
                    phoneNumber: '361-888-6232'
                },{
                    name: 'Texas Adult Day Care Center',
                    addressLine1: '3529 Morgan St,',
                    addressLine2: 'Corpus Christi, TX 78405',
                    phoneNumber: '361-881-8717'
                },{
                    name: 'TLC Adult Day Care Center',
                    addressLine1: '4410 Dillon Lane, Ste #51,',
                    addressLine2: 'Corpus Christi, TX 78415',
                    phoneNumber: '361-855-6491'
                },
            ];
            break;
        case 'AlzheimersSupportResources':
            result = [
                {
                    name: 'Alzheimer’s Association',
                    info: 'Information about Support Groups in Corpus Christi',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '800-272-3900',
                    link: 'https://www.alz.org/'
                },{
                    name: 'The Alzheimer’s Store',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '800-752-3238',
                    link: 'https://www.alzstore.com'
                },{
                    name: 'Alzheimer’s Foundation of America',
                    info: 'Support, services and education to individuals, families and caregivers',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: null,
                    link: 'https://www.alzfdn.org/'
                },{
                    name: 'Alzheimer’s Disease Education and Referral (ADEAR)',
                    info: 'Free information and publications, referrals to local support services, research and clinical trials information.',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: null,
                    link: 'https://www.nia.nih.gov/health/about-adear-center'
                },{
                    name: 'Texas Face to Face',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-238-7777',
                    link: 'https://texasfacetoface.com/'
                }
            ];
            break;
        case 'AssessmentsAndReferrels':
            result = [
                {
                    name: 'Area Agency On Aging of the Coastal Bend',
                    addressLine1: '2910 Leopard St,',
                    addressLine2: 'Corpus Christi, TX 78408',
                    phoneNumber: '(361)-883-3935 / (800)-817-5743',
                    link: 'https://www.aaacoastalbend.org/'
                },{
                    name: 'Nueces County Senior Citizens Association c/o Greenwood Senior Center',
                    addressLine1: '4040 Greenwood,',
                    addressLine2: 'Corpus Christi, TX 78416',
                    phoneNumber: '(361)-826-1368',
                    link: null
                },{
                    name: 'Texas Health Information, Counseling and Advocacy Program',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '(800)-622-2520',
                    link: null
                }
            ];
            break;
        case 'EducationAndSupport':
            result = [
                {
                    name: '2-1-1 Texas Helpline 211',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '877-541-7905',
                    link: 'https://www.211texas.org/'
                },{
                    name: 'Alzheimer’s Association',
                    info: 'Information about Support Groups in Corpus Christi.',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '800-272-3900',
                    link: 'https://www.alz.org/'
                },{
                    name: 'The Alzheimer’s Store',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '800-752-3238',
                    link: 'https://www.alzstore.com/'
                },{
                    name: 'American Diabetes Association Corpus Christi Chapter',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-850-8778',
                    link: 'https://www.diabetes.org/'
                },{
                    name: 'American Parkinson Disease Association',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '800-223-2732',
                    link: 'https://www.apdaparkinson.org/'
                },{
                    name: 'National Parkinson Foundation',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '800-473-4636',
                    link: 'https://www.parkinson.org/'
                },{
                    name: 'Texas Face to Face',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-238-7777',
                    link: 'https://texasfacetoface.com/'
                },{
                    name: 'City of Corpus Christi Senior Community Services',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-826-3150',
                    link: 'https://www.cctexas.com/parks/services/general-government/senior-services'
                },{
                    name: 'Eldercare Locator',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '800-677-1116',
                    link: 'https://www.eldercare.gov/'
                },{
                    name: 'Catholic Charities First Call for Help',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-884-0651',
                    link: 'https://www.catholiccharities-cc.org/first-call'
                },{
                    name: 'Senior Companions (City of Corpus Christi)',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-826-3154',
                    link: 'https://www.americorps.gov/'
                },{
                    name: 'Coastal Bend Aging and Disability Resource Center',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-883-3935 / 800-252-9240',
                    link: 'https://coastalbendadrc.org/'
                },{
                    name: 'Corpus Christi Literacy Council',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-826-7086',
                    link: 'https://www.ccliteracy.org/'
                },{
                    name: 'Coastal Bend Center for Independent Living',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-883-8461 / 877-988-1999',
                    link: 'https://www.cbcil.org/'
                },{
                    name: 'National Family Caregiver Support Program',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-883-3935 / 800-817-5743',
                    link: 'https://www.aaacoastalbend.org/programs/family-caregiver/'
                },{
                    name: 'Senior Community Service Employment Program (SCSEP)',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-879-0076',
                    link: 'https://www.aarpworksearch.org/'
                },{
                    name: 'VA Texas Valley Coastal Bend Health Care System',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '855-864-0516',
                    link: 'https://www.texasvalley.va.gov/'
                },{
                    name: 'Defense and Veterans Brain Injury Center',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '210-916-6086',
                    link: 'https://www.dvbic.org/'
                },{
                    name: 'Workforce Solutions of the Coastal Bend',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-882-7491 / 888-860-JOBS (5627)',
                    link: 'https://www.workforcesolutionscb.org/'
                }
            ];
            break;
        case 'GriefCounselingResources':
            result = [
                {
                    name: '2-1-1 Texas Helpline 211',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '877-541-7905',
                    link: 'https://www.211texas.org/'
                }
            ];
            break;
        case 'LegalAid':
            result = [
                {
                    name: 'Legal Hotline for Older Texans',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '800-622-2520',
                    link: 'https://www.trla.org/'
                },{
                    name: 'Texas Rio Grande Legal Aid',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-888-0282 / 888-988-9996',
                    link: 'https://www.trla.org/'
                }
            ]
            break;
        case 'Medicare / Medicaid: In Home Services':
            result = [
                {
                    name: 'Health and Human Services (HHS)',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '800-252-9240',
                    link: 'https://www.hhs.texas.gov/'
                }
            ]
            break;
        case 'Mental Health':
            result = [
                {
                    name: 'Mental Health/Mental Retardation (MHMR) of Nueces County',
                    info: '',
                    addressLine1: '1630 S. Brownlee',
                    addressLine2: 'Corpus Christi, TX 78404',
                    phoneNumber: '361-886-6900',
                    link: ''
                },{
                    name: 'Mental Health Association of TX',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '512-454-3706',
                    link: ''
                },{
                    name: 'Bayview Behavioral Hospital',
                    info: '',
                    addressLine1: '6629 Wooldridge Rd',
                    addressLine2: 'Corpus Christi, TX 78414',
                    phoneNumber: '361-986-8200',
                    link: ''
                },{
                    name: 'Spohn Behavioral Medicine Network (Memorial)',
                    info: '',
                    addressLine1: '2606 Hospital Blvd',
                    addressLine2: '',
                    phoneNumber: '1-888-767-4493',
                    link: ''
                },{
                    name: 'National Suicide Prevention Hotline',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '1-800-273-8255',
                    link: ''
                },{
                    name: 'Texas Adult Protective Services (APS) and Elder Abuse Hotline',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '1-800-252-5400 or call 911',
                    link: ''
                },{
                    name: 'Veterans Crisis Line',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '800-273-8255',
                    link: 'https://www.veteranscrisisline.net/'
                }
            ];
            break;
        case 'Nutrition':
            result = [
                {
                    name: 'Meals on Wheels Nueces County (City)',
                    info: 'Home Delivered Meals',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-826-4120',
                    link: ''
                },{
                    name: 'Meals on Wheels Port Aransas',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-749-4111',
                    link: ''
                },{
                    name: 'Rural Areas/Nueces County Community Center',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-387-6396',
                    link: ''
                },{
                    name: 'Corpus Christi-Nueces County Public Health District Healthy Foods, Healthy Families Nutrition Classes',
                    info: 'Nutrition Services',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-826-7220',
                    link: 'https://www.cctexas.com/departments/health-district'
                },{
                    name: 'SNAP- Simplified Nutritional Assistance Program',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '800-448-3927',
                    link: ''
                }
            ]
            break;
        case 'Stroke Support Resources':
            result = [
                {
                    name: 'Stroke & Brain Injury Support Group',
                    info: 'The Stroke & Brain Injury Support Group is a monthly support group for families, caregivers, and persons who have survived strokes. Meets 2nd Wednesday of every month at the solarium on the (CHRISTUS Spohn Shoreline) inpatient rehab floor from 9:30 to 11 am). For more info email: julianne.herrera@christushealth.org',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-881-3000 ext. 14415',
                    link: ''
                },{
                    name: 'Corpus Christi Stroke Club',
                    info: 'The Corpus Christi Stroke Club group meets the 3rd Thursday of every other month (January, March, May, July, September and November) at 12 pm at Doctor’s Regional Medical Center Conference',
                    addressLine1: '3315 South Alameda',
                    addressLine2: 'Corpus Christi, TX 78411',
                    phoneNumber: '361-761-1100',
                    link: ''
                },{
                    name: 'American Stroke Association – Stroke Family Warmline',
                    info: 'The Stroke Family Warmline connects stroke survivors and their families with an ASA team member who can provide support, helpful information on treatment and recovery, or just a listening ear. You can also get the latest news about research on stroke.',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '1-888-4-STROKE (478-7653)',
                    link: 'https://www.strokeassociation.org/'
                },{
                    name: 'National Stroke Association',
                    info: 'The National Stroke Association provides information about stroke recovery and resources for caregivers. There is also an online store with additional educational materials about stroke.',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '1-800-STROKES (787-6537)',
                    link: 'https://www.stroke.org/'
                },
            ]
            break;
        case 'Transportation':
            result = [
                {
                    name: 'Medical (Medicaid) Transportation Program (Medical care transportation for people receiving Medicaid)',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '877-633-8747',
                    link: ''
                },{
                    name: 'RTA B-Line Paratransit Service (transportation for people with disabilities)',
                    info: '',
                    addressLine1: '',
                    addressLine2: '',
                    phoneNumber: '361-289-5881',
                    link: ''
                },{
                    name: 'Area Agency On Aging of the Coastal Bend Transportation',
                    info: '',
                    addressLine1: '2910 Leopard St',
                    addressLine2: 'Corpus Christi, TX 78408',
                    phoneNumber: '361-883-3935',
                    link: 'https://www.aaacoastalbend.org/programs/transportation'
                },
            ]
            break;
        default:
            break;    
    }
    return result;
}  