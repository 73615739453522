import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ResourceLinksListCreator from '../components/shared/questions/ResourceLinksListCreator';
import ResourceLinksData from '../components/shared/questions/resourceLinksData';
import Layout from '../components/Layout';
import { Grid } from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function ResourceLinks() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <Layout>
        <div>
      <h1 style={{color:'#3399f3'}}>Nueces County Resources</h1>
      <p style={{fontSize:'1.3em'}}>Here is a wide selection of resources to help.</p>    
      <Grid container spacing={5} justifyContent="center" alignItems="center" style={{maxHeight: '80%',marginTop:'30px', marginBottom:'30px', color:'#333'}}>
        <Grid xs={12} md={3} alignItems='flex-start'>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <Tab label="Adult Day Care And Health Centers" {...a11yProps(0)} />
          <Tab label="Alzheimer’s Support Resources" {...a11yProps(1)} />
          <Tab label="Assessment and Referrals" {...a11yProps(2)} />
          <Tab label="Education and Support" {...a11yProps(3)} />
          <Tab label="Grief Counseling Resources" {...a11yProps(4)} />
          <Tab label="Legal Aid" {...a11yProps(5)} />
          <Tab label="Medicare / Medicaid: In Home Services" {...a11yProps(6)} />
          <Tab label="Mental Health" {...a11yProps(7)} />
          <Tab label="Nutrition" {...a11yProps(8)} />
          <Tab label="Stroke Support Resources" {...a11yProps(9)} />
          <Tab label="Transportation" {...a11yProps(10)} />
        </Tabs>
        </Grid>
        <Grid xs={12} md={8} style={{marginLeft:'20px', marginTop:'5%'}}>
        <TabPanel value={value} index={0}>
            <ResourceLinksListCreator centers={ResourceLinksData('AdultDayCareAndHealthCenters')} />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <ResourceLinksListCreator centers={ResourceLinksData('AlzheimersSupportResources')} />
        </TabPanel>
        <TabPanel value={value} index={2}>
            <ResourceLinksListCreator centers={ResourceLinksData('AssessmentsAndReferrels')} />
        </TabPanel>
        <TabPanel value={value} index={3}>
            <ResourceLinksListCreator centers={ResourceLinksData('EducationAndSupport')} />
        </TabPanel>
        <TabPanel value={value} index={4}>
            <ResourceLinksListCreator centers={ResourceLinksData('GriefCounselingResources')} />
        </TabPanel>
        <TabPanel value={value} index={5}>
            <ResourceLinksListCreator centers={ResourceLinksData('LegalAid')} />
        </TabPanel>
        <TabPanel value={value} index={6}>
            <ResourceLinksListCreator centers={ResourceLinksData('Medicare / Medicaid: In Home Services')} />
        </TabPanel>
        <TabPanel value={value} index={7}>
            <ResourceLinksListCreator centers={ResourceLinksData('Mental Health')} />
        </TabPanel>
        <TabPanel value={value} index={8}>
            <ResourceLinksListCreator centers={ResourceLinksData('Nutrition')} />
        </TabPanel>
        <TabPanel value={value} index={9}>
            <ResourceLinksListCreator centers={ResourceLinksData('Stroke Support Resources')} />
        </TabPanel>
        <TabPanel value={value} index={10}>
            <ResourceLinksListCreator centers={ResourceLinksData('Transportation')} />
        </TabPanel>
      </Grid>
      </Grid>
      </div>
      </Layout>
    );  
}