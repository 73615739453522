import React from 'react';
import Grid from '@mui/material/Grid';

export default function ResourceLinksListCreator({centers}) {
    return (
        <Grid container spacing={4} className='resourceLinksListCreator' style={{marginLeft:'2%'}}>
            {centers.map(center=>
            <Grid xs={12} md={5} style={{marginRight:'20px'}}>
                <div>
                <h2>{center.name}</h2>
                { ( center.info != null && center.info.length > 0 ) && <p style={{wordWrap: 'break-word'}}><b>Information:</b>{center.info}</p>}
                { ( center.addressLine1.length + center.addressLine2.length ) > 0 && <p style={{wordWrap: 'break-word'}}><b>Address: </b>{center.addressLine1}{center.addressLine2}</p> }
                { ( center.phoneNumber != null && center.phoneNumber.length != 0 ) && <p><b>Phone Number: </b>{center.phoneNumber}</p> }
                { ( center.link != null && center.link.length != 0 ) && <p style={{wordWrap: 'break-word'}}><b>Website:- </b><a href={center.link} target='_blank' rel='noreferrer' className='callLink'>{center.link}</a></p>}
                </div>
            </Grid>
            )}
        </Grid>
    )
}